// Here you can add other styles

/*.main {
    position: absolute !important;
    top: 40px !important;
    overflow: hidden !important;
}

.main-bredcrumb {
    position: fixed;
    top: 50px !important;
    width: 100% !important;
    z-index: 150;
    overflow: hidden !important;
}

.main-footer {
    position: absolute !important;
}*/


.CardBody {
  padding-top: 1px !important;
}


.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 343px;
  overflow-y: auto;
  padding-left: 0;
  max-width: calc(300px + 1rem);
  position: fixed;
  background-color: white;
  z-index: 9100;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}



.mymodalheader {
  padding-top: 5px;
  padding-bottom: 5px;    
}

.mymodalfooter {
  padding-top: 8px;
  padding-bottom: 8px;    
}

.mymodalbody {
  max-height: calc(100vh - 180px) ;
  overflow-y: auto;
  overflow-x: hidden;
}

.mymodalrow {
  padding-right: 10px;
}


.tablefixed  {
  border-collapse: collapse;
  width: '100%';
  //width: 300px;
  //overflow-x: auto;
  overflow-x: scroll;
  display: block;
}

.tablefixed thead {
  background-color: #EFEFEF;
}

.tablefixed thead,
.tablefixed tbody {
  display: block;
}

.tablefixed tbody {
  overflow-y: scroll;
  //overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 180px);
}

.tablefixed td,
.tablefixed th {
 // min-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
}


.myiframe {
  width: 100%;
  min-height: calc(100vh - 80px);
}





@media screen and (max-width: 520px) {
	table.responsiveku {
		width: 100%;
	}
  table.responsiveku thead {
		display: none;
	}
  table.responsiveku td {
		display: block;
    text-align: right;
    width: 100%;
	}
  table.responsiveku td::before {
		float: left;
		text-transform: uppercase;
		content: attr(data-header);
	}
}



.lebar-static {
  width: 150px;
  display: inline-block;
}

.lebar-static-2 {
  width: 200px;
  display: inline-block;
}

.lebar-static1 {
  width: 30px;
  display: inline-block;
}


.lebar-static2 {
  width: 120px;
  display: inline-block;
  text-align: right;
}

.lebar-dinamis {
  width: calc(99% - 150px) ;
  display: inline-block;
}

.lebar-dinamis-2 {
  width: calc(99% - 200px) ;
  display: inline-block;
}


.mybox {
  max-width: 793px;
  
}

.mycetak {
  padding: 10px;
  border: 1px rosybrown solid;
}